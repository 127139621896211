import React from 'react';
import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import ApolloClient from 'apollo-client';
import { Button } from 'react-bootstrap';
import { useApolloClient } from '@apollo/react-hooks';
import { FaFilePdf } from 'react-icons/fa';
import { format } from 'date-fns';
import { pdfDocOpen, styles, BoldText, PdfTable } from 'utils/pdfhelpfunc';
import { useRootStore } from 'store';


const columnsCollections = [
  { header: '', name: 'name', type: 'string' },
  { header: 'Άθροισμα Συλλογών', name: 'qty', type: 'int' },
  { header: 'Άθροισμα Θεωρητικού Βάρους Συλλογών', name: 'weight', type: 'float' },
];

const columnsQuantity = [
  { header: '', name: 'name', type: 'string' },
  { header: 'Συλλογές από Σημεία Συλλογής', name: 'collected', type: 'float' },
  { header: 'Παραδόσεις σε Σταθμούς Ανακύκλωσης', name: 'delivered', type: 'float' },
  { header: 'Διαφορά Παραδόσεων με Συλλογές', name: 'difference', type: 'float' },
  { header: 'Μονάδες Μέτρησης', name: 'unit', type: 'string' },
];

const columnsWeight = [
  { header: '', name: 'name', type: 'string' },
  { header: '', name: 'percent', type: 'percent' },
  { header: '', name: 'weight', type: 'float' },
  { header: '', name: 'unit', type: 'string' },
];

const FirstPage = ({collector, fromDate, tillDate, dataQuantity, dataWeight, dataCollections}) => (
<Page size="A4" style={styles.page}>
<View style={{ width: 580, height: 820, display: 'flex', padding: '35,30,25,20' }}>

<View style={{ flexDirection: 'row'}}>
    <View style={{ flex: 1 }}>
      <Image style={{ width: 200, height: 50 }} src={process.env.PUBLIC_URL + '/ecoelastika_logo.jpg'} />
    </View>
    <View style={{ flexDirection: 'column' }}>
    <View
      style={{
        flex: 3,
        fontSize: '1.3vh',
        alignSelf: 'flex-end',
        marginRight: '30px',
        alignItems: 'center'
      }}
    >
      <BoldText>{'ΑΝΑΛΥΣΗ ΣΥΛΛΕΧΘΕΝΤΩΝ ΜΕ'}</BoldText>
      <BoldText>{'ΠΑΡΑΔΙΔΟΜΕΝΩΝ ΕΛΑΣΤΙΚΩΝ'}</BoldText>
      
    </View>
    </View>
  </View>

    <View style={{ marginTop: '30px'}}>
      <Text style={{ alignSelf: 'center', fontSize: '1.3vh'}}>{`${collector}`}</Text>
      <Text style={{ alignSelf: 'center', fontSize: '1.3vh'}}>
            {'Ημερομηνία από:  '}
            {format(fromDate, 'dd-MM-yyyy')} {' έως  '} 
            {format(tillDate, 'dd-MM-yyyy')}
      </Text>
    </View>
      <View>
      <View style={{ display: 'flex', flexDirection: 'row', paddingTop: '10' }}>
          <View style={{ paddingLeft: '15', fontSize: '1.2vh' }}>
            <BoldText>{'Συλλογή ελαστικών – Θεωρητικό Βάρος'}</BoldText>
          </View>
        </View>

    <PdfTable data={dataCollections} columns={columnsCollections} />

      <View style={{ display: 'flex', flexDirection: 'row', paddingTop: '10' }}>
          <View style={{ paddingLeft: '15', fontSize: '1.2vh' }}>
            <BoldText>{'Συγκριτικός πίνακας Συλλεχθέντων Ελαστικών με Παραδιδόμενων Ελαστικών'}</BoldText>
          </View>
        </View>

    <PdfTable data={dataQuantity} columns={columnsQuantity} />

    <View style={{ display: 'flex', flexDirection: 'row', paddingTop: '10' }}>
          <View style={{ paddingLeft: '15', fontSize: '1.2vh' }}>
            <BoldText>{'Συγκριτικός πίνακας βαρών'}</BoldText>
          </View>
        </View>

    <PdfTable data={dataWeight} columns={columnsWeight} headers={false} />
    </View>

    <View style={{ flexDirection: 'column', alignItems: 'center', marginTop: 'auto', fontSize: '1vh'}}>
      <BoldText>{'ECOELASTIKA AE'}</BoldText>
      <Text>{'A.E. Οικολογικής Διαχείρισης Ελαστικών'}</Text>
      <Text>{'Α.Φ.Μ.: 999402660 Δ.Ο.Υ.: Φ.Α.Ε. ΑΘΗΝΩΝ'}</Text>
      <Text>{'Αρ.Γ.Ε.Μ.Η.: 005728201000'}</Text>
      <Text>{'Σώρου 14, 15125 Μαρούσι'}</Text>
      <Text>{'Τηλ: 210-6128260∙370 - Φαξ: 210-6128659'}</Text>
    </View>
 
</View>
</Page>
);

// Create Document Component
const MyDocument = ({ collector, dataQuantity, dataWeight, dataCollections, fromDate, tillDate }) => (
  <Document>
    <FirstPage dataQuantity={dataQuantity} dataWeight={dataWeight} dataCollections={dataCollections} fromDate={fromDate} tillDate={tillDate} collector={collector}></FirstPage>
  </Document>
);

export const PdfRep = ({ dataQuantity, dataWeight, dataCollections, fromDate, tillDate }) => {
  const client = useApolloClient();
  const { appStore } = useRootStore();


  //const records = [...data];
  async function printPdf(client: ApolloClient<object>): Promise<void> {
    appStore.setBusy();
    await pdfDocOpen(
      `weightDifferencesReport`,
      <MyDocument fromDate={fromDate} tillDate={tillDate} collector={appStore.userLogin.collector} dataQuantity={dataQuantity} dataWeight={dataWeight} dataCollections={dataCollections}/>
    );
    appStore.clearBusy();
  } 

  return (
    <Button style={{ width: '160px', marginLeft: '27px' }} variant="success" onClick={() => printPdf(client)}>
      <FaFilePdf className="mb-1" /> Εξαγωγή σε PDF
    </Button>
  );
};
