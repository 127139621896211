import React from 'react';
import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import ApolloClient from 'apollo-client';
import { useApolloClient } from '@apollo/react-hooks';
import { FaFilePdf } from 'react-icons/fa';
import { pdfDocOpen, styles, BoldText, PdfTable } from 'utils/pdfhelpfunc';
import { useRootStore } from 'store';

/* const getCurrentDate = (separator = '') => {
  const newDate = new Date();
  const date = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();

  return `${date < 10 ? `0${date}` : `${date}`}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${year}`;
}; */

const columns = [
  { header: 'Column1', name: 'column1', type: 'string', left: true },
  { header: 'Column2', name: 'column2', type: 'string' },
];

const MyPage = ({forName, allData, mainTable, orderTable, collectionTable}) => (
<Page size="A4" style={styles.page}>
<View style={{ width: 580, height: 820, display: 'flex', padding: '50,30,25,30' }}>

  <View style={{ flexDirection: 'row'}}>
    <View style={{ flex: 1 }}>
      <Image style={{ width: 200, height: 50 }} src={process.env.PUBLIC_URL + '/ecoelastika_logo.jpg'} />
    </View>
    <View style={{ flexDirection: 'column' }}>
    <View
      style={{
        flex: 3,
        fontSize: '1.3vh',
        alignSelf: 'flex-end',
        marginRight: '30px',
        alignItems: 'center'
      }}
    >
      <BoldText>{'ΑΠΟΔΕΙΚΤΙΚΟ ΠΑΡΑΔΟΣΗΣ – ΠΑΡΑΛΑΒΗΣ'}</BoldText>
      <BoldText>{'ΑΧΡΗΣΤΩΝ ΕΛΑΣΤΙΚΩΝ'}</BoldText>
      <Text>{forName}</Text>
    </View>
    </View>
  </View>

  <View style={{ flexDirection: 'row', marginTop: '10px'}}>
    <View style={{ border: 1, borderColor: '#0f0f0f', marginRight: '20px', marginTop: '50px', flex: 2}}>
      <View style={{borderBottom: 1, borderColor: '#0f0f0f'}}>
  <BoldText style={{ alignSelf: 'center', paddingTop: '2px', fontSize: '1.1vh'}}>{'Στοιχεία του Σημείου Συλλογής'}</BoldText>
  </View>
  <View style={{ flexDirection: 'column', alignItems: 'center', marginTop: '10px'}}>
   {allData.CstPointName ? (<Text>{allData.CstPointName + "\n\n"}</Text>) : undefined} 
   {allData.CstPointAddress ? (<Text>{allData.CstPointAddress}</Text>) : undefined} 
   {allData.CstPointMunicipality ? (<Text>{allData.CstPointMunicipality}</Text>) : undefined} 
   {allData.CstPointNomos ? (<Text>{allData.CstPointNomos}</Text>) : undefined} 
   {allData.CstPointVAT ? (<Text>{"ΑΦΜ: " + allData.CstPointVAT}</Text>) : undefined} 
   {allData.CstPointIRS ? (<Text>{"ΔΟΥ: " + allData.CstPointIRS}</Text>) : undefined} 
   {allData.CstPointPhone1 ? (<Text>{"ΤΗΛ: " + allData.CstPointPhone1}</Text>) : undefined} 
  </View>
  </View>
  <View style={{ flex: 3, alignSelf: 'flex-end'}}>
  <PdfTable data={mainTable} columns={columns} headers={false} />
  </View>
  </View>

  <View style={{ flexDirection: 'row', marginTop: '0px'}}>
    <View style={{marginRight: '15px', flex: 2}}>
  <PdfTable data={orderTable} columns={columns} headers={false} />
  </View>
  <View style={{flex: 2}}>
  <PdfTable data={collectionTable} columns={columns} headers={false} />
  </View>
    </View>

    <View style={{ flexDirection: 'row', marginTop: '10px'}}> 
      <View style={{ border: 1, borderColor: '#0f0f0f', marginRight: '20px', flex: 2}}>
      <View style={{ flexGrow: 1, flexDirection: 'column', marginTop: '2px', alignItems: 'center', fontSize: '0.9vh'}}>
      <Text>{'Δηλώνω ότι έχω λάβει γνώση και αποδέχομαι τους'}</Text>
      <Text>{'ΓΕΝΙΚΟΥΣ ΟΡΟΥΣ ΠΑΡΑΔΟΣΗΣ - ΠΑΡΑΛΑΒΗΣ'}</Text>
      <Text>{'ΜΕΤΑΧΕΙΡΙΣΜΕΝΩΝ ΕΛΑΣΤΙΚΩΝ'}</Text>
      </View>
      <Text style={{alignSelf: 'center', marginTop: '70px'}}>{'(Σφραγίδα Σημείου Συλλογής)'}</Text>
      </View>

      <View style={{ border: 1, borderColor: '#0f0f0f', flex: 2}}>
      <View style={{ flexGrow: 1}}></View>
      <Text style={{alignSelf: 'center', marginTop: '70px'}}>{'Ποσότητα Ολογράφως'}</Text>
      </View>
    </View>

    <View style={{ flexDirection: 'column', alignItems: 'flex-start', marginTop: '10px', fontSize: '0.9vh'}}>
      <BoldText>{'ΓΕΝΙΚΟΙ ΟΡΟΙ ΠΑΡΑΔΟΣΗΣ/ΠΑΡΑΛΑΒΗΣ ΜΕΤΑΧΕΙΡΙΣΜΕΝΩΝ ΕΛΑΣΤΙΚΩΝ\n\n'}</BoldText>
      <Text>{'1. Το σημείο συλλογής φέρει ευθύνη για την ορθή αναγραφή των παραδιδόμενων ποσοτήτων μεταχειρισμένων ελαστικών ανά κατηγορία. Το παρόν έντυπο φυλάσσεται από το σημείο συλλογής για χρονικό διάστημα ενός έτους. Η ECOELASTIKA έχει δικαίωμα να διενεργεί ελέγχους για τη σωστή αναγραφή των ποσοτήτων. Τα στοιχεία μπορούν να κοινοποιούνται από την ECOELASTIKA στις αρμόδιες δημόσιες αρχές, εφόσον ζητούνται στο πλαίσιο της διαδικασίας αδειοδότησης.\n\n'}</Text>
      <Text>{'2. Η ECOELASTIKA δεν παραλαμβάνει από τα σημεία συλλογής ελαστικά οποιασδήποτε κατηγορίας με εξωτερική διάμετρο ίση ή μεγαλύτερη από 1600mm, συμπαγή ελαστικά.\n\n'}</Text>
      <Text>{'3. Τα μεταχειρισμένα ελαστικά που θα παραδίδονται από το σημείο συλλογής θα είναι ολόκληρα και καθαρά, δε θα περιέχουν νερά και θα είναι απαλλαγμένα ξένων υλών (όπως π.χ. ζάντες, σαμπρέλες, ξύλα, πλαστικά, πέτρες, νερό και γενικά διάφορα άλλα απορρίμματα).\n\n'}</Text>
      <Text>{'4. Τα μεταχειρισμένα ελαστικά θα πρέπει να βρίσκονται συγκεντρωμένα στα σημεία συλλογής σε ντάνες ανά κατηγορία και σε εύκολο σημείο πρόσβασης του φορτηγού του εξουσιοδοτημένου από την ECOELASTIKA συλλέκτη. Σε κάθε περίπτωση η απόσταση του σημείου συγκέντρωσης των ελαστικών δε θα πρέπει να υπερβαίνει τα 5 μέτρα από το σημείο, στο οποίο μπορεί να σταθμεύσει το όχημα μεταφοράς. H αποθήκευση των ελαστικών δεν γίνεται σε κάδους συλλογής - αποθήκευσης αδρανών υλικών.'}</Text>
    </View>

    <View style={{ flexDirection: 'column', alignItems: 'center', marginTop: '10px', fontSize: '1vh'}}>
      <BoldText>{'ECOELASTIKA AE'}</BoldText>
      <Text>{'A.E. Οικολογικής Διαχείρισης Ελαστικών'}</Text>
      <Text>{'Α.Φ.Μ.: 999402660 Δ.Ο.Υ.: Φ.Α.Ε. ΑΘΗΝΩΝ'}</Text>
      <Text>{'Αρ.Γ.Ε.Μ.Η.: 005728201000'}</Text>
      <Text>{'Σώρου 14, 15125 Μαρούσι'}</Text>
      <Text>{'Τηλ: 210-6128260∙370 - Φαξ: 210-6128659'}</Text>
    </View>
</View>
</Page>);

// Create Document Component
const MyDocument = ({ allData, mainTable, orderTable, collectionTable }) => (
  <Document>
    <MyPage allData={allData} mainTable={mainTable} orderTable={orderTable} collectionTable={collectionTable} forName={'ΓΙΑ ΤΟ ΣΗΜΕΙΟ ΣΥΛΛΟΓΗΣ'}></MyPage>
    <MyPage allData={allData} mainTable={mainTable} orderTable={orderTable} collectionTable={collectionTable} forName={'ΓΙΑ ΤΟΝ ΣΥΛΛΕΚΤΗ'}></MyPage>
  </Document>
);

export const PdfRep = ({ data }) => {
  const { appStore } = useRootStore();
  const client = useApolloClient();
 const processedDataMain = [
   {column1: "Κωδικός Αίτησης Συλλογής", column2: data.CodeId},
   {column1: "'Ωρα", column2: ""},
   {column1: "Αριθμός Μεταφορικού Μέσου", column2: ""},
   {column1: "Τόπος Προορισμού", column2: ""},
   {column1: "Συλλέκτης", column2: data.CollectorName },
   {column1: "Σκοπός Διακίνησης", column2: "Ανακύκλωση"}
  ]

  const processedDataOrder = [
    {column1: "Ημ/νία Παραγγελίας", column2: data.RequestDate, date: 'column2'},
    {column1: "ΠΑΡΑΓΓΕΛΙΑ ΣΗΜΕΙΟΥ", column2: "Μ.Μ. ΤΕΜΑΧΙΑ", bold: 'column1'},
    {column1: "Κατηγορία Α", column2: data.TyreType1_ReqQty, int: 'column2'},
    {column1: "Κατηγορία Β", column2: data.TyreType2_ReqQty, int: 'column2'},
    {column1: "Κατηγορία Γ", column2: data.TyreType3_ReqQty, int: 'column2'},
    {column1: "Κατηγορία Δ1", column2: data.TyreType4_ReqQty, int: 'column2'},
    {column1: "Κατηγορία Δ2", column2: data.TyreType5_ReqQty, int: 'column2'},
   ]

   const processedDataCollection = [
    {column1: "Ημ/νία Παραλαβής", column2: "    /     /    "},
    {column1: "ΠΑΡΑΛΑΒΗ ΣΗΜΕΙΟΥ", column2: "Μ.Μ. ΤΕΜΑΧΙΑ", bold: 'column1'},
    {column1: "Κατηγορία Α", column2: ""},
    {column1: "Κατηγορία Β", column2: ""},
    {column1: "Κατηγορία Γ", column2: ""},
    {column1: "Κατηγορία Δ1", column2: ""},
    {column1: "Κατηγορία Δ2", column2: ""},
   ]
  async function printPdf(client: ApolloClient<object>): Promise<void> {
    
appStore.setBusy();
    await pdfDocOpen(
      `OrderReport`,
      <MyDocument allData={data} mainTable={processedDataMain} orderTable={processedDataOrder} collectionTable={processedDataCollection}/>,
    );
    appStore.clearBusy();
  }

  return (
    <FaFilePdf size={20} style={{ color: 'red', cursor: 'pointer'}} onClick={() => printPdf(client)} />
  );
};
