import React from 'react';
import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import ApolloClient from 'apollo-client';
import { Button } from 'react-bootstrap';
import { useApolloClient } from '@apollo/react-hooks';
import { FaFilePdf } from 'react-icons/fa';
import { format } from 'date-fns';
import { pdfDocOpen, styles, BoldText, PdfTable } from 'utils/pdfhelpfunc';
import { useRootStore } from 'store';


const columns = [
  { header: 'Σταθμός Ανακύκλωσης', name: 'XName', type: 'string' },
  { header: 'Βάρος Υλικών(kg)', name: 'YValue', type: 'float' },
];

const FirstPage = ({collector, getDocument, fromDate, tillDate, data}) => (
<Page size="A4" style={styles.page}>
<View style={{ width: 580, height: 820, display: 'flex', padding: '50,30,25,30' }}>

<View style={{ flexDirection: 'row'}}>
    <View style={{ flex: 1 }}>
      <Image style={{ width: 200, height: 50 }} src={process.env.PUBLIC_URL + '/ecoelastika_logo.jpg'} />
    </View>
    <View style={{ flexDirection: 'column' }}>
    <View
      style={{
        flex: 3,
        fontSize: '1.3vh',
        alignSelf: 'flex-end',
        marginRight: '30px',
        alignItems: 'center'
      }}
    >
      <BoldText>{'ΑΝΑΛΥΣΗ ΠΑΡΑΛΑΒΩΝ'}</BoldText>
      <BoldText>{'ΣΤΑΘΜΩΝ ΑΝΑΚΥΚΛΩΣΗΣ'}</BoldText>
      
    </View>
    </View>
  </View>

    <View style={{ marginTop: '50px'}}>
      <Text style={{ alignSelf: 'center', fontSize: '1.3vh'}}>{`${collector}`}</Text>
      <Text style={{ alignSelf: 'center', fontSize: '1.3vh'}}>
            {'Ημερομηνία από:  '}
            {format(fromDate, 'dd-MM-yyyy')} {' έως  '} 
            {format(tillDate, 'dd-MM-yyyy')}
      </Text>
      <Image style={{ width: 580, height: 300 }} src={getDocument} />
    </View>
      <View>
    <PdfTable data={data} columns={columns} />
    </View>

    <View style={{ flexDirection: 'column', alignItems: 'center', marginTop: 'auto', fontSize: '1vh'}}>
      <BoldText>{'ECOELASTIKA AE'}</BoldText>
      <Text>{'A.E. Οικολογικής Διαχείρισης Ελαστικών'}</Text>
      <Text>{'Α.Φ.Μ.: 999402660 Δ.Ο.Υ.: Φ.Α.Ε. ΑΘΗΝΩΝ'}</Text>
      <Text>{'Αρ.Γ.Ε.Μ.Η.: 005728201000'}</Text>
      <Text>{'Σώρου 14, 15125 Μαρούσι'}</Text>
      <Text>{'Τηλ: 210-6128260∙370 - Φαξ: 210-6128659'}</Text>
    </View>
 
</View>
</Page>
);

// Create Document Component
const MyDocument = ({ collector, allData, getDocument, fromDate, tillDate }) => (
  <Document>
    <FirstPage data={allData} fromDate={fromDate} tillDate={tillDate} collector={collector} getDocument={getDocument}></FirstPage>
  </Document>
);

export const PdfRep = ({ getDocument, data, fromDate, tillDate }) => {
  const client = useApolloClient();
  const { appStore } = useRootStore();


  const records = [...data];
  async function printPdf(client: ApolloClient<object>): Promise<void> {
    appStore.setBusy();
    await pdfDocOpen(
      `stationsDeliveriesReport`,
      <MyDocument fromDate={fromDate} tillDate={tillDate} collector={appStore.userLogin.collector} allData={records} getDocument={getDocument}/>
    );
    appStore.clearBusy();
  } 

  return (
    <Button style={{ width: '160px', marginLeft: '27px' }} variant="success" onClick={() => printPdf(client)}>
      <FaFilePdf className="mb-1" /> Εξαγωγή σε PDF
    </Button>
  );
};
